import React from 'react'

export interface Props {
  className?: string;
  maxWidth?: string;
  id?: string;
  border?: string;
}

export function Divider({
  className,
  id,
  maxWidth,
  border,
}: Props) {
  return (
    <hr
      id={id}
      className={`${className}`}
      style={{
        width: maxWidth,
        border
      }}
    />
  )
}
